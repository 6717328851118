export default function SpinningTawa() {
  jQuery(document).ready(function ($) {
    // Spinning Tawa scripts
    const tawaItems = {
      0: {
        name: "The Turkish Tawa",
        description:
          "A large dish consisting of seven different types of meat, all served on a sizzling tawa",
        imageURL: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_01.jpg",
        angle: 0,
        class: "flex items-center justify-center no-img text-center",
      },
      1: {
        name: "Chicken Kofte",
        description: "Marinated and chargrilled pieces of chicken mince",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_01.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_02.jpg",
        angle: 35,
        class: "flex items-center justify-center",
      },
      2: {
        name: "Lamb Kofte",
        description: "Marinated and chargrilled pieces of lamb mince",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_02.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_03.jpg",
        angle: 60,
        class: "flex items-center justify-center",
      },
      3: {
        name: "Lamb Ribs",
        description:
          "Marinated Lamb ribs with our traditional spices, chargrilled",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_03.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_04.jpg",
        angle: 120,
        class: "flex items-center justify-center",
      },
      4: {
        name: "Lamb Skewers",
        description:
          "Marinated and chargrilled succulent pieces of lamb off-the-bone.",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_04.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_05.jpg",
        angle: 150,
        class: "flex items-center justify-center",
      },
      5: {
        name: "Chicken Wings",
        description:
          "Marinated chicken wings with our traditional spices, chargrilled",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_05.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_06.jpg",
        angle: 191,
        class: "flex items-center justify-center",
      },
      6: {
        name: "Chicken Skewers",
        description:
          "Marinated and chargrilled succulent pieces of chicken breast.",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_06.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_07.jpg",
        angle: 225,
        class: "flex items-center justify-center",
      },
      7: {
        name: "Lamb Chops",
        description: "Marinated lamb chops, spiced and chargrilled.",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_07.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_08.jpg",
        angle: 250,
        class: "flex items-center justify-center",
      },
      8: {
        name: "Steak Chips",
        description: "Chunky steak cut chips, fried with the skin off",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_08.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_09.jpg",
        angle: 290,
        class: "flex items-center justify-center",
      },
      9: {
        name: "Rice",
        description: "Bulgar rice, flavoured with our in house spices",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_09.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_10.jpg",
        angle: 320,
        class: "flex items-center justify-center",
      },
      10: {
        name: "Vegetables",
        description: "Peppers, tomatoes and gorgeously caramelised onions",
        imageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/small/tawa_small_10.jpg",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_11.jpg",
        angle: 340,
        class: "flex items-center justify-center",
      },
      11: {
        name: "Sizzling & Hot",
        description:
          "All made fresh to order with fresh ingredients, on a sizzling hot tawa, true mediterranean style",
        imageURL: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
        mainImageURL:
          "/wp-content/themes/vl-theme-olive/assets/images/tawa/tawa_12.jpg",
        angle: 360,
        class: "flex items-center justify-center no-img text-center",
      },
    };

    // Create an array for all mainImageURL in tawaItems
    let tawaImagesURL = [];
    for (const meal in tawaItems) {
      tawaImagesURL.push(tawaItems[meal].mainImageURL);
    }

    // Function to check the counter doesn't go out of bounds of array
    let counter = 0;
    const checkCounter = (count) => {
      if (count > tawaImagesURL.length - 1) {
        resetTawa();
        count = 0;
      }
      if (count < 0) {
        emptyTawa();
        count = tawaImagesURL.length - 1;
      }
      return count;
    };

    // Function that resets the tawa by looping through each image and swapping the src with setTimeout, creating an effect that looks as if
    // the tawa items are being added one by one
    const resetTawa = () => {
      // Make a copy of tawaImagesURL array but in reverse
      const reversedtawaImages = [...tawaImagesURL].reverse();
      for (let i = 0; i < reversedtawaImages.length; i++) {
        setTimeout(function () {
          $("#tawa-img").attr("src", reversedtawaImages[i]);
        }, i * 100);
      }
    };

    // Function that empties the tawa by looping through each image and swapping the src with setTimeout, creating an effect that looks as if
    // the tawa items are being taken off one by one
    const emptyTawa = () => {
      for (let i = 0; i < tawaImagesURL.length; i++) {
        setTimeout(function () {
          $("#tawa-img").attr("src", tawaImagesURL[i]).fadeIn("slow");
        }, i * 100);
      }
    };

    // Decreases the counter by one (to go back a tawa item) and invokes the checkCounter function to make sure it isn't out of bounds.
    const rotateBack = () => {
      counter--;
      counter = checkCounter(counter);

      changetawaContent();
    };

    // Increases the counter by one (to go forward a tawa item) and invokes the checkCounter function to make sure it isn't out of bounds.
    const rotateNext = () => {
      counter++;
      counter = checkCounter(counter);

      changetawaContent();
    };

    // Changes to content = Rotation and src of tawa image, fade effect on the tawa item name, description and image.
    const changetawaContent = () => {
      const tawaItem = tawaItems[counter];
      $("#tawa-img").css("transform", `rotate(${tawaItem.angle}deg)`);
      $("#tawa-img").attr("src", tawaItem.mainImageURL);
      $("#tawa-item-name").hide().html(tawaItem.name).fadeIn("slow");
      $("#tawa-item-description")
        .hide()
        .html(tawaItem.description)
        .fadeIn("slow");

      document.getElementById("tawa-item-wrapper").classList = tawaItem.class; // This updates class list of tawa item wrapper
      $("#tawa-item-img").hide().attr("src", tawaItem.imageURL).fadeIn("slow");
    };

    $("#tawa-back-btn").click(() => {
      rotateBack();
    });

    $("#tawa-next-btn").click(() => {
      rotateNext();
    });
  });
}
