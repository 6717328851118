import "../vendor/vimeo-froogaloop2.min";

export let isOpen;
isOpen = false;

export function openBgOverlay() {
  jQuery("#overlay_bg").toggleClass("scale-y-0 scale-y-100");
}

export function stopScrolling() {
  jQuery("body").toggleClass("relative h-full overflow-hidden");
}

// VIDEO!!
// functions for animating the video modal
export function openVideoModal() {
  jQuery("#video-modal").toggleClass("hidden");
}

export function animateVideoModal() {
  jQuery("#video-modal").toggleClass(
    "opacity-100 opacity-0 translate-y-12 translate-y-0"
  );
}

export function iframePlay() {
  jQuery(document).ready(function ($) {
    var iframe = $("#vimeo-player")[0];
    var player = $f(iframe);

    player.api("play");
    isOpen = true;
  });
}

export function iframePause() {
  jQuery(document).ready(function ($) {
    var iframe = $("#vimeo-player")[0];
    var player = $f(iframe);

    player.api("pause");
    isOpen = false;
  });
}

export default function Video() {
 // open the nav button
  jQuery(".open_video_modal").on("click", function () {
    setTimeout(openVideoModal);
    setTimeout(animateVideoModal, 300);
    setTimeout(openBgOverlay);
    setTimeout(stopScrolling);

    iframePlay();
  });

  jQuery(".close_video_modal").on("click", function () {
    setTimeout(animateVideoModal);
    setTimeout(openVideoModal, 350);
    setTimeout(openBgOverlay, 500);
    setTimeout(stopScrolling, 500);

    iframePause();
  });
}
