import "lightgallery";
import "lg-thumbnail";
import "lg-video";

export default function Gallery() {
  jQuery(document).ready(function ($) {
    // our food page gallery for customer images
    if (jQuery("#lightgallery").length) {
      jQuery("#lightgallery").lightGallery({
        mode: "lg-fade",
        cssEasing: "cubic-bezier(0.25, 0, 0.25, 1)",
        selector: ".lightgallery-item",
        hash: false,
        download: false,
        thumbnail: true,
        subHtmlSelectorRelative: true,
        loadYoutubeThumbnail: true,
        youtubeThumbSize: "default",
      });
    }
  });
}
