import barba from "@barba/core";
import barbaPrefetch from "@barba/prefetch";
import { pageTransition } from "./preloader";

function delay(n) {
  n = n || 2000;
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, n);
  });
}

export default function Transitions(_first, _enter) {
  barba.use(barbaPrefetch);

  barba.hooks.beforeEnter(() => {
    // Scroll to top
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  });

  barba.hooks.after((data) => {
    ga("set", "page", window.location.pathname);
    ga("send", "pageview");
  });

  barba.init({
    sync: true,
    prevent: ({ el }) => el.classList && el.classList.contains('wpcf7-submit'),
    transitions: [
      {
        name: "app-transition",
        async once() {
          _first();
        },
        async leave() {
          const done = this.async();
          pageTransition();
          await delay(1200);
          done();
        },
        async enter() {
          _enter();
        },
        async after(data) {
          const elVideos = document.querySelectorAll("video");
          elVideos.forEach((el) => {
            el.hasAttribute("autoplay") && el.play();
          });

          let parser = new DOMParser();
          let htmlDoc = parser.parseFromString(data.next.html.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', data.next.html), 'text/html');
          let bodyClasses = htmlDoc.querySelector('notbody').getAttribute('class');
          document.body.className = bodyClasses;
        }
      },
    ],
    views: [
      {
        namespace: "home",
        beforeEnter() {
          jQuery("#main-menu > li").removeClass("current_item");
        },
      },
      {
        namespace: "contact",
        beforeEnter({ next }) {
          const cf_selector = 'div.wpcf7 > form';
          const cf_forms = jQuery(next.container).find(cf_selector);
          if (cf_forms.length>0) {
            jQuery(cf_selector).each(function() {
                const form = jQuery(this);
                wpcf7.init(form[0]); 
            });
          }   
        },
      },
    ],
  });
}
