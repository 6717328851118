export let clicks;
clicks = 0;

function openMenuScreen() {
  jQuery("#menu--wrapper").toggleClass("hidden");

  jQuery("#hamb-wrapper").toggleClass("top-1/2 top-0 mt-0 -mt-1");
  jQuery("#hamb-middle").toggleClass("hidden");
  jQuery("#hamb-top").toggleClass(
    "w-4 w-6 rotate-45 pt-2 absolute top-1/2 left-0 -transform-half"
  );
  jQuery("#hamb-bottom").toggleClass(
    "w-5 w-6 -rotate-45 pt-1 top-1/2 left-0 -transform-half absolute"
  );
  jQuery("#hamb-open-text").toggleClass("block hidden");
  jQuery("#hamb-close-text").toggleClass("block hidden");
}

function openMenuOverlay() {
  jQuery("#menu--overlay").toggleClass(
    "opacity-0 invisible opacity-40 visible"
  );
}

function animateMenuBg() {
  jQuery("#menu--bg").toggleClass("scale-y-0 scale-y-100");
}

function animateMenuLinks() {
  jQuery("#main-menu > li").each(function (i) {
    var t = jQuery(this);
    setTimeout(function () {
      t.toggleClass("translate-y-0 -translate-y-12 opacity-0 opacity-100");
    }, (i + 1) * 45);

    t.on("click", function(e) {
      jQuery("#main-menu > li").removeClass("current_item");
      e.target.parentElement.classList.add("current_item");
    });
  });
}

// change z-index on header just for the homepage
function changeHeaderZ() {
  jQuery(".front_page_header").toggleClass("z-30 z-50");
}

function stopScrolling() {
  jQuery("body").toggleClass("relative h-full overflow-hidden");
}

function MenuOpen() {
  setTimeout(stopScrolling);
  setTimeout(openMenuOverlay, 100);
  setTimeout(openMenuScreen, 100);
  setTimeout(animateMenuBg, 200);
  setTimeout(animateMenuLinks, 300);
  setTimeout(changeHeaderZ);

  clicks++;
}

export function MenuClose() {
  setTimeout(animateMenuLinks);
  setTimeout(animateMenuBg, 350);
  setTimeout(openMenuScreen, 1000);
  setTimeout(openMenuOverlay, 1000);
  setTimeout(changeHeaderZ, 1200);

  clicks--;
}

function MenuToggle() {
  if (clicks == 0) {
    MenuOpen();
  } else {
    setTimeout(stopScrolling, 1000);
    MenuClose();
  }
}

export default function MainMenu() {
  // open the nav button
  jQuery(".open_menu_screen").on("click", function () {
    window.scrollTo(0, 0);
    MenuToggle();
  });
}
