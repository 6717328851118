import { CountUp } from "countup.js";

import "../vendor/jquery.waypoints";

export function startCount() {
  var counterOptions = {
    duration: 3,
  };
  const counters = document.querySelectorAll(".counter");
  counters.forEach((counter) => {
    var countUp = new CountUp(
      counter,
      counter.getAttribute("data-to"),
      counterOptions
    );
    countUp.start();
  });
}

export default function Stats() {
  jQuery(document).ready(function ($) {
    if ($("#stats").length) {
      var stats_waypoint = new Waypoint({
        element: document.getElementById("stats"),
        handler: function (direction) {
          if (direction == "down") {
            startCount();
          }
          this.destroy();
        },
        offset: "80%",
      });
    }
  });
}
