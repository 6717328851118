//// vendor JS files
import "jquery-match-height";
import "./vendor/isotope.pkgd";

import LocomotiveScroll from "locomotive-scroll";
import imagesLoaded from "imagesloaded";
import Alpine from "alpinejs";
import barba from "@barba/core";

import { ScrollTrigger } from "gsap/ScrollTrigger";

//// local js files
import HomeSlider from "./components/home-sliders";
import MenuPage from "./components/menu-page";
import MainMenu from "./components/main-menu";
import SpinningTawa from "./components/spinning-tawa";
import BgVideo from "./components/bgVideo";
import Video from "./components/video";
import Sliders from "./components/sliders";
import Gallery from "./components/gallery";
import Stats from "./components/stats";
import Transitions from "./components/barba-custom";
import Loading, { loadingEnter } from "./components/preloader";
import Morphext from "./components/morphext";
import ImageReveals from "./components/imagereveals";
import cursor from "./components/cursor";
// import Popup from "./components/popup";

Alpine.start();

jQuery(document).ready(function ($) {
  // fades the banner background on scroll 200px
  jQuery(window).scroll(function () {
    jQuery(".element-with-video-bg , header").css(
      "opacity",
      1 - jQuery(window).scrollTop() / $(window).height()
    );
  });

  $(function () {
    $(".matchHeight").matchHeight();
  });
});

// function setCookie(name, value, daysToLive) {
//   // Encode value in order to escape semicolons, commas, and whitespace
//   var cookie = name + "=" + encodeURIComponent(value);

//   if (typeof daysToLive === "number") {
//     /* Sets the max-age attribute so that the cookie expires
//       after the specified number of days */
//     cookie += "; max-age=" + daysToLive * 24 * 60 * 60;

//     document.cookie = cookie;
//   }
// }

// function getCookie(name) {
//   // Split cookie string and get all individual name=value pairs in an array
//   var cookieArr = document.cookie.split(";");

//   // Loop through the array elements
//   for (var i = 0; i < cookieArr.length; i++) {
//     var cookiePair = cookieArr[i].split("=");

//     /* Removing whitespace at the beginning of the cookie name
//       and compare it with the given string */
//     if (name == cookiePair[0].trim()) {
//       // Decode the cookie value and return
//       return decodeURIComponent(cookiePair[1]);
//     }
//   }

//   // Return null if not found
//   return null;
// }

function Init() {
  HomeSlider();
  MenuPage();
  SpinningTawa();
  BgVideo();
  Video();
  Sliders();
  Stats();
  Gallery();
  Morphext();
  ImageReveals();
  cursor();

  // if (!getCookie("has_seen_popup")) {
  //   Popup();

  //   setCookie("has_seen_popup", "true", 1);
  // }
}

// const scrollContainer = document.querySelector("[data-scroll-container]");

// let scroll;
// scroll = new LocomotiveScroll({
//   el: scrollContainer,
//   smooth: true,
// });

// imagesLoaded(scrollContainer, { background: true }, function () {
//   scroll.update();
// });

function First() {
  MainMenu();
  Init();
  Loading(() => {
    // console.log("complete first");
  });
}

function Enter() {
  Init();
  loadingEnter(() => {
    // console.log("complete enter");
  });
}

Transitions(
  () => {
    First();
  },
  () => {
    Enter();
  }
);

// barba.hooks.after(() => {
//   imagesLoaded(scrollContainer, { background: true }, function () {
//     scroll.update();
//   });
// });
