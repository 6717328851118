import "flickity";
import "flickity-imagesloaded";
import "flickity-sync"

export default function Sliders() {
  jQuery(document).ready(function ($) {
    // homepage reviews slider
    if ($(".reviews_slider").length) {
      var $reviews_slider = $(".reviews_slider").flickity({
        prevNextButtons: false,
        pageDots: false,
        selectedAttraction: 0.04,
        friction: 0.3,
        adaptiveHeight: true,
      });

      // previous
      $("#prev-review").on("click", function () {
        $reviews_slider.flickity("previous");
      });
      // next
      $("#next-review").on("click", function () {
        $reviews_slider.flickity("next");
      });
    }

    // restaurant slider
    if ($("#restaurant_slider").length) {
      var $restaurant_slider = $("#restaurant_slider").flickity({
        fade: true,
        draggable: false,
        pauseAutoPlayOnHover: false,
        autoPlay: 2500,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#restaurant_slider_venue").length) {
      var $restaurant_slider_venue = $("#restaurant_slider_venue").flickity({
        autoPlay: 3000,
        draggable: false,
        center: true,
        pauseAutoPlayOnHover: false,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#food_slider").length) {
      var $food_slider = $("#food_slider").flickity({
        fade: true,
        draggable: false,
        pauseAutoPlayOnHover: false,
        autoPlay: 3000,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#food_slider_drinks").length) {
      var $food_slider_drinks = $("#food_slider_drinks").flickity({
        draggable: false,
        pauseAutoPlayOnHover: false,
        fade: true,
        autoPlay: 3000,
        pauseAutoPlayOnHover: false,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#food_slider_desserts").length) {
      var $food_slider_desserts = $("#food_slider_desserts").flickity({
        draggable: false,
        pauseAutoPlayOnHover: false,
        fade: true,
        autoPlay: 3000,
        pauseAutoPlayOnHover: false,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#food_slider_turkish").length) {
      var $food_slider_turkish = $("#food_slider_turkish").flickity({
        autoPlay: 3000,
        draggable: false,
        pauseAutoPlayOnHover: false,
        center: true,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#menu_slider").length) {
      var $menu_slider = $("#menu_slider").flickity({
        sync: ".menu-carousel",
        draggable: false,
        fade: true,
        imagesLoaded: true,
        wrapAround: false,
        prevNextButtons: false,
        pageDots: false,
      });
    }

    if ($("#contact_slider").length) {
      var $contact_slider = $("#contact_slider").flickity({
        fade: true,
        autoPlay: 2500,
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
      });
    }
  });
}
