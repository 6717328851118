import gsap from "gsap";

export default function ImageReveals() {
    

    jQuery(document).ready(function ($) {

        $(".image-reveal-wrapper").waypoint(function(direction){
            $(this[0,'element']).addClass("reveal");
        },{
        offset: '85%'
        });

        $("#tawa-img").waypoint(function(direction){
            $(this[0,'element']).addClass("reveal");
        },{
        offset: '75%'
        });
    });
      
}
