import gsap from "gsap";
import { clicks, MenuClose } from "./main-menu";
import { isOpen, animateVideoModal, iframePause, openBgOverlay, openVideoModal, stopScrolling } from "./video";

const elLoading = ["#preloader", ".preloader-layer",];

const loading_end = {
  scaleY: 0,
  transformOrigin: "bottom",
  display: "none",
  stagger: {
    amount: 0.15,
    from: "end",
  },
  duration: 1.2,
  ease: "expo.inOut",
};

const loading_start = {
  scaleY: 1,
  transformOrigin: "top",
  stagger: {
    amount: 0.15,
    from: "start"
  },
  duration: 1.4,
  ease: "expo.inOut",
  display: "block",
};

export default async function Loading(_callback) {
  const tlLoadcomplete = gsap.timeline({
    onComplete: _callback(),
  });

  tlLoadcomplete.to(elLoading, loading_end);
}

export function loadingEnter(_callback) {
  const tlLoadcompleteenter = gsap.timeline({
    delay: 0.3,
    onComplete: _callback(),
  });

  tlLoadcompleteenter.to(elLoading, loading_end);

  tlLoadcompleteenter.fromTo(
    "#pagenamespace",
    {
      top: -80,
    },
    {
      top: 0,
      ease: "expo.out",
      duration: 1.6,
      onComplete: function(){
        gsap.set("#pagenamespace", {
          clearProps: "all",
        });
      }
    },
    "-=0.6"
  );
}

export function pageTransition() {
  const tlTransition = gsap.timeline({
    onComplete: function () {
      gsap.set("#main-menu, .front_page_header", {
        clearProps: "all",
      });
    },
  });

  if (isOpen === true) {
    setTimeout(animateVideoModal);
    setTimeout(openVideoModal, 350);
    setTimeout(openBgOverlay, 500);
    setTimeout(stopScrolling, 500);

    iframePause();
  }

  if (clicks == 0) {
  } else {
    // gsap.to("#main-menu, .front_page_header", {
    //   ease: "power1.out",
    //   duration: 0.3,
    //   opacity: 0,
    //   stagger: 0,
    //   onComplete: function() {
    //     MenuClose();
    //   }
    // });

    setTimeout(() => {
      MenuClose();
    }, 800);
  }

  tlTransition.to(elLoading, loading_start);

  tlTransition.to(
    "#pagenamespace",
    {
      top: 80,
      ease: "expo.out",
      duration: 1.6,
    },
    "-=0.8"
  );
}
