export default function HomeSlider() {
  function setUpSlider(id) {
    const $ = jQuery;
    var mainClassName = ".home-page-carousel" + id;
    if ($(mainClassName).length) {
      var time = 5;
      var $bar, $ourfood_carousel, tick, percentTime, isPaused;

      // var sliderWrapper = document.querySelector(".home-page-carousel-wrapper2");
      // var isPaused = false;

      $ourfood_carousel = $(mainClassName);
      $ourfood_carousel.flickity({
        prevNextButtons: false,
        draggable: false,
        pageDots: true,
        selectedAttraction: 0.04,
        friction: 0.3,
        fade: true,
        lazyLoad: true,
        imagesLoaded: true
      });

      $(".home-page-carousel-wrapper" + id + " .flickity-page-dots .dot").click(
        function () {
          resetProgressbar();
          startProgressbar();
        }
      );

      $bar = $(
        ".home-page-carousel-wrapper" + id + " .slider-progress .progress"
      );

      function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        tick = setInterval(interval, 10);
      }

      function interval() {
        percentTime += 1 / (time + 0.1);
        $bar.css({
          width: percentTime + "%",
        });
        if (percentTime >= 100) {
          $ourfood_carousel.flickity("next", true);
          startProgressbar();
        }
      }

      function resetProgressbar() {
        $bar.css({
          width: 0 + "%",
        });
        clearTimeout(tick);
      }
      startProgressbar();

      // Flickity instance
      var flkty = $ourfood_carousel.data("flickity");
      // elements
      var $cellButtonGroup = $(
        ".home-page-carousel-wrapper" + id + " .home-page-toggle"
      );
      var $cellButtons = $cellButtonGroup.find("span");

      // update selected cellButtons
      $ourfood_carousel.on("select.flickity", function () {
        $cellButtons.filter(".is-selected").removeClass("is-selected");
        $cellButtons.eq(flkty.selectedIndex).addClass("is-selected");
      });

      // select cell on button click
      $cellButtonGroup.on("click", "span", function () {
        var index = $(this).index();
        $ourfood_carousel.flickity("select", index);
        resetProgressbar();
        startProgressbar();
      });
      // // previous
      // $('.button--previous').on( 'click', function() {
      //   $ourfood_carousel.flickity('previous');
      // });
      // // next
      // $('.button--next').on( 'click', function() {
      //   $ourfood_carousel.flickity('next');
      // });
    } else {
      // console.log("SetupSlider: Attempted to find class that doesn't exist.");
      return;
    }
  }

  jQuery(document).ready(function ($) {
    if ($("#pagenamespace").attr("data-barba-namespace") === "home") {
      setUpSlider(1);
      setUpSlider(2);
    }
  });
}

// jQuery(document).ready(function ($) {
//   SetupSlider(1);
//   SetupSlider(2);
// });
