import "flickity";
import "flickity-fade";
import "flickity-sync";
import "flickity-imagesloaded";

export default function MenuPage() {
  jQuery(document).ready(function ($) {
    // menu page carousel for menu categories
    if (jQuery(".menu-carousel").length) {
      // Flickity
      // menu page filter
      var $food_menu_carousel = $(".menu-carousel").flickity({
        prevNextButtons: false,
        pageDots: false,
        selectedAttraction: 0.04,
        friction: 0.3,
        adaptiveHeight: true,
      });

      // Flickity instance
      var flkty = $food_menu_carousel.data("flickity");
      // elements
      var $cellButtonGroup = $("#parent_menu_filter");
      var $cellButtons = $cellButtonGroup.find("button");

      // update selected cellButtons
      $food_menu_carousel.on("select.flickity", function () {
        $cellButtons.filter(".is-selected").removeClass("is-selected");
        $cellButtons.eq(flkty.selectedIndex).addClass("is-selected");
      });

      // select cell on button click
      $cellButtonGroup.on("click", "button", function () {
        var index = $(this).index();
        $food_menu_carousel.flickity("select", index);
      });
      // // previous
      // $('.button--previous').on( 'click', function() {
      //   $food_menu_carousel.flickity('previous');
      // });
      // // next
      // $('.button--next').on( 'click', function() {
      //   $food_menu_carousel.flickity('next');
      // });
    }

    // MENU JS

    // const removeMeatFilters = () => {
    //   $("[name='food_type']").each(function () {
    //     $(this).prop("checked", false);
    //   });
    // };

    // const removeDietFilters = () => {
    //   $("[name='diet_type']").each(function () {
    //     if (this.id == "filter_vegetarian" || this.id == "filter_vegan") {
    //       $(this).prop("checked", false);
    //     }
    //   });
    // };

    // const meatFilters = [
    //   "filter_beef",
    //   "filter_chicken",
    //   "filter_lamb",
    //   "filter_mixed",
    //   "filter_seafood",
    // ];
    // $(document).on(
    //   "click",
    //   "#meat_menu_filter input, #diet_type_filter input",
    //   function (event) {
    //     let selectedList = [];
    //     if (
    //       event.target.id === "filter_vegetarian" ||
    //       event.target.id === "filter_vegan"
    //     ) {
    //       if (event.target.id == "filter_vegetarian") {
    //         $("#filter_vegan").prop("checked", true);
    //       }
    //       removeMeatFilters();
    //     }

    //     if (meatFilters.includes(event.target.id)) {
    //       removeDietFilters();
    //     }

    //     $("input[name='diet_type']:checked").each(function () {
    //       selectedList.push(this);
    //     });

    //     $("input[name='food_type']:checked").each(function () {
    //       selectedList.push(this);
    //     });

    //     updateCurrentlyViewing(selectedList);
    //     filterItems(selectedList);
    //   }
    // );

    const removeFilters = () => {
      $("#menu_filter input").each(function () {
        if (this.id !== "filter_all") {
          $(this).removeAttr("checked");
        }
      });
    };

    const showAllItems = () => {
      console.log("showing all items");
      $(".food_item").each(function () {
        $(this).removeClass("opacity-20");
        $(this).addClass("opacity-100");
      });
    };

    $(document).on("click", "#menu_filter input", function (event) {
      let selectedList = [];

      if (this.id === "filter_all") {
        removeFilters();
        showAllItems();
        selectedList.push(this);
      } else {
        $("#filter_all").removeAttr("checked");
        $("input:checked").each(function () {
          selectedList.push(this);
        });
        filterItems(selectedList);
      }

      updateCurrentlyViewing(selectedList);
    });

    const capitalise = (words) => {
      var separateWord = words.toLowerCase().split(" ");
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(" ");
    };

    const updateCurrentlyViewing = (filters) => {
      let string = "";
      if (filters.length == 0) {
        string = "Please choose a filter";
      } else {
        string = "Currently Viewing: ";
        for (let i = 0; i < filters.length; i++) {
          if (i == filters.length - 1) {
            string += `${capitalise(filters[i].value)}`;
          } else {
            string += `${capitalise(filters[i].value)}, `;
          }
        }
      }

      $("#applied-filters").html(string);
    };

    const filterItems = (filters) => {
      $(".food_item").each(function () {
        let inFilter = false;
        for (let i = 0; i < filters.length; i++) {
          if ($(this).hasClass(filters[i].id)) {
            inFilter = true;
            break;
          }
        }

        if (inFilter) {
          $(this).removeClass("opacity-20");
          $(this).addClass("opacity-100");
        } else {
          $(this).removeClass("opacity-100");
          $(this).addClass("opacity-20");
        }
      });
    };

    const hideSmMenuFilters = (id) => {
      $(".menu-filter").each(function () {
        if ($("button", this).attr("id") !== id) {
          $(".menu-mobile-filters", this).removeClass("flex");
          $(".menu-mobile-filters", this).addClass("hidden");
          $("i", this).removeClass("rotate-90");
        }
      });
    };

    $(".menu-filter button").on("click", function () {
      hideSmMenuFilters($(this).attr("id"));
      $(this).siblings("div").toggleClass("hidden flex");
      $("i", this).toggleClass("rotate-90");
    });

    $("#menu-items-carousel").on("change.flickity", function (event, index) {
      const elem = $(".flickity-slider > div", this)[index];
      $("#course").html($("h1", elem).html());
    });
  });
}
