import "jquery-background-video";

export default function BgVideo() {
  jQuery(document).ready(function ($) {
    //homepage background video
    $(".my-background-video").bgVideo({
      // fullScreen: true,
      showPausePlay: false, // Show pause/play button
      pauseAfter: 0,
    });
  });
}
